// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-habitat-js": () => import("./../../../src/pages/habitat.js" /* webpackChunkName: "component---src-pages-habitat-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-koala-problems-js": () => import("./../../../src/pages/koala-problems.js" /* webpackChunkName: "component---src-pages-koala-problems-js" */),
  "component---src-pages-new-page-js": () => import("./../../../src/pages/new-page.js" /* webpackChunkName: "component---src-pages-new-page-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tree-farm-project-js": () => import("./../../../src/pages/tree-farm-project.js" /* webpackChunkName: "component---src-pages-tree-farm-project-js" */)
}

